import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-intl'
import { makeStyles } from '@material-ui/core'
import Helmet from 'react-helmet'
import Container from '@material-ui/core/Container'
import BackgroundImage from 'gatsby-background-image'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import COLORS from '../constants/colors'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    paddingTop: 80,
  },
  container: {
    marginLeft: 0,
    marginRight: 0,
    '@media (max-width:600px)': {
      marginTop: 20,
      marginBottom: 0,
    },
  },
  textGrid: {
    maxWidth: '70vw',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  heroImage: {
    height: 400,
    '@media (max-width:600px)': {
      height: 'auto',
    },
  },
  title: {
    paddingTop: 40,
    paddingBottom: 40,
    '@media (max-width:600px)': {
      fontSize: 20,
      width: '100%',
      marginBottom: 26,
    },
  },
  body: {
    paddingTop: 60,
  },
  back: {
    fontSize: '0.7em',
    color: COLORS.GREEN,
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'none',
      textDecorationColor: COLORS.GREEN,
    },
    '&:visited': {
      textDecoration: 'none',
      color: COLORS.GREEN,
    },
    '@media (max-width:600px)': {
      fontSize: '8px !important',
    },
  },
  newsPost: {
    whiteSpace: 'pre-line',
    '& p': {
      margin: 0,
    },
    '& a': {
      color: COLORS.GREEN,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: COLORS.GREEN,
      },
      '&:visited': {
        color: COLORS.GREEN,
      },
    },
  },
  bgImage: {
    height: '40vw',
    width: '70vw',
    backgroundSize: 'cover',
  },
})

const BlogPostTemplate = ({ data }) => {
  const classes = useStyles()
  const post = data.contentfulBlogPost
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout darkVersion={true}>
      <Container
        className={classes.root}
        maxWidth="xl"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Seo description={post.title} image={post.heroImage.fluid.src} />
        <Container maxWidth="xl">
          <h1> NEWS </h1>
        </Container>
        <Helmet title={`${post.title} | ${siteTitle}`} />
        <Container className={classes.textGrid}>
          <Link to="/news" style={{ textDecoration: 'none' }}>
            <p style={{ textDecoration: 'none' }} className={classes.back}>
              BACK TO NEWS
            </p>
          </Link>
          <BackgroundImage
            Tag="section"
            className={classes.bgImage}
            fluid={post.heroImage.fluid}
          />
          <h2 className={classes.title}>{post.title}</h2>
          <p
            style={{
              display: 'block',
              fontWeight: 'bold',
            }}
          >
            {post.publishDate}
          </p>
          <span className={classes.newsPostObj}>
            <div
              className={classes.newsPost}
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          </span>
          <Link to="/news" style={{ textDecoration: 'none' }}>
            <p style={{ textDecoration: 'none' }} className={classes.back}>
              BACK TO NEWS
            </p>
          </Link>
        </Container>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
